import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import React from 'react';
import { States } from '../../constants/DropdownConstants';

class SelectState extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedState: '',
    };
  }

  getState = () => {
    return this.state.selectedState;
  };

  onStateChange = (event) => {
    this.setState({
      selectedState: event.target.value,
    });
    this.props.onChange && this.props.onChange(event.target.value);
  };

  render() {
    const { selectedState } = this.state;
    return (
      <>
        <Form.Group>
          <Form.Control
            id='state'
            as='select'
            onChange={this.onStateChange}
            value={selectedState}
          >
            <option value='' disabled>
              State
            </option>
            {States.map((state, index) => {
              return (
                <option value={state} key={index}>
                  {state}
                </option>
              );
            })}
          </Form.Control>
        </Form.Group>
      </>
    );
  }
}

SelectState.propTypes = {
  onChange: PropTypes.func,
};

export default SelectState;
