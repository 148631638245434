import MCSupplementNavigation from '../components/form/mc/mcSupplementalNavigation';
import React from 'react';

class Supplement extends React.Component {
  render() {
    return <MCSupplementNavigation></MCSupplementNavigation>;
  }
}

export default Supplement;
