import { Col, Container, Row } from 'react-bootstrap';

import { FaInfoCircle } from 'react-icons/fa';
import PropTypes from 'prop-types';
import React from 'react';
import { RiLightbulbFlashFill } from 'react-icons/ri';
import SEO from '../seo';
import SecuredCard from '../subComponents/securedCard';
import SubFooter from '../subComponents/subFooter';

const SidebarLayout = ({ children, subFooter, securityIcons }) => {
  return (
    <>
      <SEO title='Get Rates Now' />

      <Row className='g-0 sidebar-layout'>
        <Col
          xs={12}
          sm={12}
          md={4}
          lg={3}
          xl={2}
          className='d-none d-md-block pr-0'
        >
          <div className='sidebar-section'>
            <div className='sidebar-section-item'>
              <div className='sidebar-section-item-title'>
                <FaInfoCircle color='#3AB110' />
                <h5>Find accurate prices</h5>
              </div>
              <div className='sidebar-section-item-desc'>
                <p>
                  The price of a Medicare plan can vary based on your age,
                  gender and usage of tobacco.
                </p>
              </div>
            </div>
            <hr />
            <div className='sidebar-section-item'>
              <div className='sidebar-section-item-title'>
                <RiLightbulbFlashFill color='#EFCE4A' />
                <h5>Did you know?</h5>
              </div>
              <div className='sidebar-section-item-desc'>
                <p>
                  You can save up to 15% in Household discounts if you buy a
                  Medicare Supplement (Medigap) Plan for you and your spouse
                  together.
                </p>
              </div>
            </div>
            <hr />
          </div>
        </Col>
        <Col xs={12} sm={12} md={8} lg={9} xl={10} className='lg:pl-0'>
          <section className='common-section'>
            <Container fluid>
              <Row className='g-0'>
                <Col
                  xs={12}
                  lg={{ offset: 1, span: 9 }}
                  xl={{ offset: 1, span: 6 }}
                >
                  <div className={subFooter ? 'step3-footer-spacer' : ''}>
                    <main className='sidebar-layout-main'>{children}</main>
                    <div
                      style={{
                        paddingLeft: 15,
                        paddingRight: 15,
                        marginTop: 30,
                        marginBottom: 10,
                      }}
                    >
                      {securityIcons && <SecuredCard />}
                    </div>
                  </div>
                  {subFooter && (
                    <div style={{ paddingLeft: 15, paddingRight: 15 }}>
                      <SubFooter />
                    </div>
                  )}
                </Col>
              </Row>
            </Container>
          </section>
        </Col>
      </Row>
    </>
  );
};

SidebarLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  subFooter: PropTypes.bool,
  securityIcons: PropTypes.bool,
};

SidebarLayout.defaultProps = {
  subFooter: false,
  securityIcons: false,
};

export default SidebarLayout;
