import React, { Component } from 'react';

import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

class RadioInput extends Component {
  onInputChange = (event) => {
    this.props.onChange(event.target.value);
  };

  render() {
    const {
      id,
      isInvalid,
      required,
      errorMessage,
      name,
      options,
      value,
      validated,
    } = this.props;

    const attributes = {
      id,
      required,
      name,
    };

    return (
      <Form.Group className='sm-mb10 xs-mb10' {...attributes}>
        {options.map((option, index) => {
          return (
            <div
              className='form-input-group'
              key={index}
              style={{ height: 50 }}
              onClick={() => this.props.onChange(option.value)}
            >
              <Form.Check
                type='radio'
                id={id + '-' + option.id}
                name={name}
                value={option.value}
                checked={value === option.value}
                onChange={this.onInputChange}
                isInvalid={isInvalid}
                required={required}
              />
              <Form.Check.Label className='font-family-secondary'>{`${option.label}`}</Form.Check.Label>
            </div>
          );
        })}
        <div
          className='invalid-feedback'
          style={
            required && validated && isEmpty(value)
              ? { display: 'unset' }
              : { display: 'none' }
          }
        >
          {errorMessage}
        </div>
      </Form.Group>
    );
  }
}

RadioInput.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  isInvalid: PropTypes.bool,
  required: PropTypes.bool,
  errorMessage: PropTypes.string,
  name: PropTypes.string.isRequired,
  options: PropTypes.array,
  validated: PropTypes.bool,
};

export default RadioInput;
